var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('el-breadcrumb',{attrs:{"separator":">"}},[_c('el-breadcrumb-item',{attrs:{"to":{
        path:
          _vm.breadcrumbs.c1name == '高级分析'
            ? '/Home/HighAll'
            : _vm.breadcrumbs.c2name == '单细胞分析'
            ? '/Cell'
            : _vm.breadcrumbs.c2name == '微生物生信'
            ? '/Home/microorganisms'
            : _vm.breadcrumbs.c2name == '肿瘤生信'
            ? '/Home/Tumorbioinformatics'
            : _vm.breadcrumbs.c2name == '转录组分析'
            ? '/Home/transcriptomeAnalysis'
            : _vm.breadcrumbs.c2name == '蛋白组分析'
            ? '/Home/ProteomeAnalysis':'',
      }},nativeOn:{"click":function($event){return _vm.jump(_vm.breadcrumbs.c2)}}},[_vm._v(_vm._s(_vm.breadcrumbs.c2name))]),_c('el-breadcrumb-item',[_vm._v(_vm._s(_vm.breadcrumbs.name))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
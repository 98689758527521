import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const biologicalInformationAnalysis = [
    {
        path: "ProteomeAnalysis",
        name: "ProteomeAnalysis",
        component: () => import("@/views/biologicalInformationProcess/ProteomeAnalysis/ProteomeAnalysis.vue"),
        
    },  
    {
        path: "GOEnrichment_ProteomeAnalysis",
        name: "GOEnrichment_ProteomeAnalysis",
        component: () => import("@/views/biologicalInformationProcess/ProteomeAnalysis/GOEnrichment/index.vue"),
    },  
    {
        path: "KEGGEnrichment_ProteomeAnalysis",
        name: "KEGGEnrichment_ProteomeAnalysis",
        component: () => import("@/views/biologicalInformationProcess/ProteomeAnalysis/KEGGEnrichment/index.vue"),
    }, 
    
]
const WithTypes = biologicalInformationAnalysis.map(item => {
    if (!item.meta) {
        // 没有meta对象
        return {
            ...item,
            meta: {
                type: "蛋白组分析",
            },
        };
    }

    // 有meta对象
    return {
        ...item,
        meta: {
            ...item.meta,
            type: "蛋白组分析", // 替换成你想要的类型值
        },
    };
});

export default WithTypes
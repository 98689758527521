export default {
    // Home Page
    HomePage: {
        navetop_left: {
            dataAnalysis: 'Data Analysis',
            memberCenter: 'Member Center',
            LiteratureL: 'Literature',
            AITool: 'AI Tool',
            CNSReplication: 'CNS Replication',
            MechanismDiagram: 'Mechanism Diagram',
            personalized: 'Personalized'
        },
        navetop_right: {
            subtitle: 'A nationwide online scientific research data (bioinformatics) analysis cloud platform built with tens of millions of investments, revolutionizing traditional data analysis tools and modes, providing an unprecedented data analysis experience! Every month, 30 new visualization tools are continuously added, covering all fields of analysis methods, elevating the convenience of data analysis to an unprecedented level!',
            SVIP: 'Noble',
            Hello: 'Hello',
            user: 'Dear user',
            login: 'Login/Register',
            message: 'message center',
            layOut: 'Log Out'
        },
        nave_b: {
            first: {
                dataAnalysis: 'Data Analysis',
                text: 'Revolutionizing traditional data analysis tools and modes'
            },
            second: {
                writingTtools: 'Literature Search/Writing Tools',
                text: 'SCI writing has never been so simple and efficient'
            },
            third: {
                AITool: 'AI Tool',
                text: 'Save chat records with a stable official GPT'
            }
        },
        dataDetail: {
            text: 'Data Analysis Module - Generate 500 CNS series charts with one click in one second, becoming a data analysis master',
            TurnTexts: [
                "Data analysis has never been so refreshing; you can easily create CNS-level high-quality charts for articles within one minute without struggling to learn code, surpassing and replacing R language.",
                "Often knowing what chart to create is more important than knowing how to create it. Collect 300 charting tools in one go, find the matching chart, and maximize the value and significance behind the data.",
                "CNSKnowall specializes in scientific data analysis, dedicated to helping researchers produce any chart they want with minimal cost.",
                "Transform traditional data analysis models and processes, completing various chart visualizations with one click, making scientific data analysis effortless."
            ],
            videoOut1: 'Innovation 1: Transforming the raw data upload mode',
            videoOut2: 'Innovation 2: Redefining chart coloring mode',
            lists1: {
                li1: 'Intelligent Excel text editing box',
                li2: 'Intelligent Excel text editing box',
                li3: 'Arbitrary modification of row and column data',
                li4: 'Fastest data upload online'
            },
            lists2: {
                li1: 'First-of-its-kind one-click random color change mode',
                li2: 'Dual palettes for arbitrary color selection',
                li3: 'First-of-its-kind color picker for top journal colors',
                li4: 'Coloring far exceeds R language'
            }
        },
        bottomDetails: {
            dataDetail: "The CNSKnowall platform was founded by master's and doctoral students from Fudan University Shanghai Medical College and Shanghai Jiao Tong University School of Medicine. CNS refers to the abbreviations of the top three journals Cell, Nature, and Science, and Knowall implies comprehensive knowledge and simplicity. The platform aims to enable researchers to achieve CNS-level research quality and standards effortlessly. It innovatively revolutionizes traditional data analysis tools and modes, reducing the difficulty of scientific charting, bioinformatics analysis, and statistical analysis to an unprecedented level! CNSKnowall was created to address the increasing demand for high-difficulty data analysis and the limitations of outdated tools. Compared to SPSS, Origin, GraphPad Prism, and R language, CNSKnowall offers a series of groundbreaking innovations in data uploading, color adjustment, and parameter modification. With minimal learning costs (both time and money), the platform provides over 400 data analysis visualization charts along with sample data, fundamentally changing the traditional mode of downloading software and searching for tutorials and codes. Researchers can use preformatted data templates to quickly generate CNS-level charts with simple operations, making high-level data analysis accessible to everyone.",
            dataText: 'Embark on a journey of efficient scientific data analysis, instantly becoming a data analysis expert, paving the way for scientific excellence!',
            NoLogin: 'Log In Now',
            login: 'Use Now'
        },
        bottom: {
            book: 'Little Red Book',
            zhihu: 'Zhihu',
            video: 'Video Channel',
            bili: 'Bilibili',
            BaijiaHao: 'Baidu Baijia',
            Tiktok: 'TikTok'
        },
        Company: {
            Cname: 'Shanghai CNS Biomedicine Technology Co., Ltd.',
            record: 'Shanghai ICP 2023016389',
            address: 'Lujiazui Riverside Center, Pudong, Shanghai',
            email: '18211280007@fudan.edu.cn'
        },
        slide_contain: {
            service: 'customer hotline',
            questions: 'common problem',
            scan: 'scan'
        }
    },
    Aside: {
        li1: 'General Analysis',
        li2: 'Basic Analysis',
        li3: 'Advanced Analysis',
        li4: 'Bioinformatics Analysis',
        li5: 'Microbial Bioinformatics',
        li6: 'Oncology Bioinformatics',
        li7: 'Omics Workflow',
        li8: 'Single Cell',
        li9: 'Transcriptomics Analysis',
        li10: 'Proteomics Analysis',
        li11: 'Metabolomics',
        li12: 'Custom Analysis',
        li13: 'Analysis Records',
        li14: 'My Favorites'
    },
    NavTop: {
        index: 'Home',
        search: 'Search',
        memberCenter: 'Member Center',
        dataAnalysis: 'Data Analysis',
        LiteratureL: 'Literature',
        AITool: 'AI',
        CNSReplication: 'CNS Replication',
        MechanismDiagram: 'Mechanism Diagram',
        video: 'Video Demonstration',
        new: 'Must-Read for New Users',
        subtitle: 'The largest online scientific research data (bioinformatics) analysis cloud platform in the country, built at a cost of tens of millions, completely revolutionizes traditional data analysis tools and methods, delivering an unprecedented data analysis experience! 30 new visualization tools are added every month, covering all fields of analysis methods, taking the convenience of data analysis to an unprecedented level! Built at a cost of tens of millions to create the largest in the country.',
        SVIP: 'Prestigious',
        Hello: 'Hello',
        user: 'Dear User',
        login: 'Login/Register',
        message: 'Message Center',
        layOut: 'Logout',
        li1: '① Recommend using Sogou and Google Chrome browsers; There is a one month free trial period for new registrations. Please try it out as soon as possible, and you can continue to use it for free through sharing and forwarding in the future! (To avoid uncontrollable factors, the use of Firefox and 360 browser is prohibited)',
        li2: '② Prioritize uploading through the copy and paste function (press Ctrl C+V on the keyboard) in the text box on the right side of each chart module, and try not to choose the file upload method. The first row of uploaded data cannot be missing column names, especially in cell A-1 where column names must be present!',
        li3: "③ Press' Ctrl+0 'on the keyboard to restore the 100% interface'",
        li4: '④ Do not open multiple drawing tools at the same time, as it may cause the PDF to fail to open after downloading'
    },
    BottomContainAll: {
        folding: 'One click folding',
        expansion: 'One click expansion',
        top: 'Top'
    },
    PersonalCenter: {
        MyNews: 'Personal Information',
        VIPData: 'Membership Expiration Date',
        extendTime:'renew',
        function: 'Function Categories',
        share: 'New registration or sharing to earn rewards',
        pay: 'Paid Membership',
        user: 'SVIP User',
        allUse: 'Unlimited usage for all members',
        num: 'Unlimited use of 500 data analysis modules, 30 new tools added monthly, charts for article publishing with accompanying code',
        td1: 'Unlimited use of literature search tools',
        td2: 'Unlimited use of experimental mechanism/abstract diagram modules',
        td3: 'Customer support quick response',
        td4: 'Priority resolution, service hours: 8:00 AM - 12:00 AM',
        td5: 'Includes all holidays, including the Spring Festival',
        td6: 'Official OpenAI GPT-3.5+GPT-4 (can be recharged separately), no VPN required, pay-per-use without high monthly fees',
        td7: 'Recharge required after using up free tokens from new registrations',
        td8: 'Daily',
        td9: 'Gift',
        td10: '5 coins (equivalent to',
        td11: 'questions on GPT-3.5/',
        td12: 'questions on GPT-4.0)',
        td13: 'Save analysis records, review historical data and results anytime, conduct reanalysis, or adjust colors and parameters',
        td14: 'Save up to 10 analysis records',
        td15: 'Save up to 200 analysis records',
        td16: 'SVIP Exclusive Benefits (199/year)',
        td17: "Unlimited consultations with Ph.D. and master's level customer support from Fudan and Jiaotong University for data analysis methods during the SVIP period, helping maximize the value of your hard-earned data",
        td18: 'One tool expedited online within one week',
        td19: 'Join the literature download service group; one SCI paper download request per day for papers without personal access',
        td120: 'During the SVIP period, receive a 100 RMB cash bonus for every article citing the CNSknowall platform (99 RMB for annual SVIP; lifetime SVIP can claim a lifetime bonus of 100 RMB per article with no limit on the number of articles). Contact customer service for direct WeChat transfers.',
        td21: '(View citation format)',
        td22: 'Private one-on-one customer service for unlimited consultations during SVIP on how to choose tools suitable for your data. The CNSknowall team has extensive expertise in scientific plotting, providing methodological guidance to save you time and effort!',
        td222:'R language one-to-one reproduction of high-quality code download permissions',
        td23: 'All chart parameter adjustments can be customized to your needs, completed on the same day, available after the 7:00 PM platform update',
        td24: "Join the SVIP exclusive WeChat service group! We have specially hired master's and doctoral students in the field of life sciences from top domestic universities such as Fudan University, Jiao Tong University, Chinese Academy of Sciences, and Peking University, as well as top bioinformatics engineers covering various omics research directions, to serve SVIP members throughout the process. We are available on holidays and weekends, and SVIP members in the group can answer questions and receive the most professional data analysis methods or analysis direction guidance, various experimental process guidance, full process guidance for paper publication, and academic and career planning guidance! Share valuable research and learning materials irregularly!",
        td25: '(View SVIP service group case studies)',
        td26: 'No payment required; share on Moments to get 1-month VIP membership. Thanks for your support!',
        td27: 'Considering that most of our users are financially limited students, we launched the share-for-membership activity, valid permanently (free forever)!',
        td28: 'Step 1',
        td29: 'Follow the "CNSknowall" public account',
        td30: 'Scan the QR code on the right side of the homepage or search on WeChat to follow',
        td31: 'Step 2',
        td32: 'Choose the first article selected by the author, "If analyzing scientific research data is difficult, you might not know this treasure data plotting platform!" Share on Moments (cannot set to view by specific groups only)',
        td33: 'Step 3',
        td34: 'Upload a screenshot of the Moments post 6 hours after sharing to extend the membership by 1 month. Additional days are granted based on likes: N days = Number of likes (e.g., 100 likes = 100 extra days). If limited group visibility is set, no more than 3 people can be blocked, verified by a screenshot.',
        td35: 'Upload',
        td36: 'Successfully uploaded, awaiting review',
        td37: 'Check upload record',
        td38: 'Upload once a month',
        td39: 'Can be used for free indefinitely!',
        td40: 'Experience unparalleled speed in data analysis and become an expert instantly!',
        td41: '(CNSknowall - Best value for membership)',
        td42: 'Scan QR code for easier payment',
        td43: 'Download Lifetime SVIP Contract',
        td44: 'During the SVIP period, receive a 100 RMB reward for every article citing the CNSknowall platform (no limit on the number of articles)',
        td45: 'Supports issuing invoices before bank transfer. Contact customer service for inquiries.',
        td55: 'Issue Invoice',
        td56: '(Contact customer service to issue)',
        td57: '① Purchase of data analysis tool usage rights',
        td58: '② Data analysis guidance service',
        td59: '③ Scientific research technical services',
        td60: '④ Data analysis services',
        td61: 'Lifetime SVIP group price: 999 RMB per person for three people. Contact customer service for inquiries.',
        td62: '① Citation format for article methodology section',
        td63: '② Citation format for article acknowledgments section',
        td64: 'Copy',
        td67: 'Format for reference only; other expressions are acceptable, but the URL must match!',
        td68: ''
    }


}

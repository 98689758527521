export default {
    // 首页
    HomePage: {
        navetop_left: {
            dataAnalysis: '数据分析',
            memberCenter: '会员中心',
            LiteratureL: '文献探索',
            AITool: 'AI工具',
            CNSReplication: 'CNS复现',
            MechanismDiagram: '机制图',
            personalized: '个性化定制'
        },
        navetop_right: {
            subtitle: '耗资上千万搭建的全国最大的在线科研数据（生信）分析云平台，彻底颠覆和变革传统数据分析工具和分析模式，只为带给您前所未有的数据分析体验!   每月持续上新30个可视化图表工具，涵盖全领域分析方法，将数据分析的便捷度提高到前所有未的高度！耗资上千万搭建的全国最大的',
            SVIP: '尊贵的',
            Hello: '您好',
            user: '尊敬的用户',
            login: '登录/注册',
            message: '消息中心',
            layOut: '退出登录'
        },
        nave_b: {
            first: {
                dataAnalysis: '数据分析',
                text: '颠覆和变革传统数据分析工具和分析模式'
            },
            second: {
                writingTtools: '文献检索/写作工具',
                text: 'SCI撰写从未如此简单高效'
            },
            third: {
                AITool: 'AI工具',
                text: '可保存聊天记录且稳定的官方GPT'
            }

        },
        dataDetail: {
            text: '数据分析模块-500款CNS系列图表1秒内1键出图，秒变数据分析大师',
            TurnTexts: [
                "数据分析从未如此神清气爽，不需要费力学习代码也能1分钟内轻松画出CNS级别高分文章图表，替代并超越R语言",
                "很多时候知道画什么图往往比会画什么图更重要，一次性收藏300个画图工具，找到匹配图表，最大化挖掘数据背后的价值和意义",
                "CNSKnowall专注科研数据分析，致力于帮助广大科研工作者用最小的成本画出任何自己想要的图表",
                "变革传统数据分析模式和流程，一键完成各种图表可视化，让天下没有难做的科研数据分析",
            ],
            videoOut1: '创新1：变革原始数据上传模式',
            videoOut2: '创新2：重新定义图表配色模式',
            lists1: {
                li1: '智能Excel文本编辑框',
                li2: '智能Excel文本编辑框',
                li3: '各行列数据任意修改',
                li4: '全网上传数据最快x'
            },
            lists2: {
                li1: '首创一键随机换色模式',
                li2: '双调色板任意选色',
                li3: '首创取色器一键调取顶刊配色',
                li4: '配色方式远超R语言'
            }
        },
        bottomDetails: {
            dataDetail: 'CNS万事通(CNSKnowall)平台由复旦大学上海医学院和上海交通大学医学院硕博合伙创办，CNS是三大顶刊Cell、Nature和Science的缩写简称，knowall寓意万事皆通、诸事皆易，CNSknowall平台旨在让广大科研同行都能轻松做出CNS顶刊的研究水平和质量，创新性的颠覆和变革了传统数据分析工具和模式，将科研绘图、生信分析和统计分析的难度降低到前所未有的程度！CNSKnowall平台创立的初衷就是为了解决目前科研同行日益增长的高难度数据分析需求与落后的数据分析工具无法达到要求之间的矛盾，和目前常用的数据分析工具如SPSS、Origin、GraphPad Prsim和R语言相比，CNSknowall在数据上传、配色修改和参数调整等各方面做出了一系列颠覆性重大创新，各项性能遥遥领先，几乎没有任何学习成本(包括时间成本和金钱成本)。平台直接提供了>400种数据分析可视化图表以及配套示例数据，彻底改变了传统分析工具需要先下载软件、再去费时费力搜索各种图表教程和代码的模式，您只需要简单的套用平台提供的针对每个图表的固定数据格式(模板)，将自己数据整理成想绘制的目标图表的示例数据格式，然后将数据粘贴到平台后仅需鼠标点点点就可以1秒1键完成CNS级别的高水平图表制作，让没有任何数据分析、生信分析和可视化基础的小白用最快的速度、最低的成本越级进阶数据分析可视化大师，让科研界同行分析CNS级别图表和统计如同吃饭喝水一样简单!',
            dataText: '迈入极速高效科研数据分析之旅，秒变数据分析大师，开启科研天骄之路！',
            NoLogin: '立即登录',
            login: '立即使用'
        },
        bottom: {
            book: '小红书',
            zhihu: '知乎',
            video: '视频号',
            bili: 'Bilibili',
            BaijiaHao: '百家号',
            Tiktok: '抖音'
        },
        Company: {
            Cname: '上海森恩艾斯(CNS)生物医药科技有限公司',
            record: '沪ICP备2023016389号',
            address: '上海市浦东新区陆家嘴滨江中心',
            email: '18211280007@fudan.edu.cn'
        },
        slide_contain: {
            service: '客服热线',
            questions: '常见问题',
            scan: '扫一扫'
        }
    },
    Aside: {
        li1: '通用分析',
        li2: '基础分析',
        li3: '高阶分析',
        li4: '生信分析',
        li5: '微生物生信',
        li6: '肿瘤生信',
        li7: '组学流程',
        li8: '单细胞',
        li9: '转录组分析',
        li10: '蛋白组分析',
        li11: '代谢组',
        li12: '定制分析',
        li13: '分析记录',
        li14: '我的收藏'
    },
    NavTop: {
        index: '首页',
        search:'搜索',
        memberCenter: '会员中心',
        dataAnalysis: '数据分析',
        LiteratureL: '文献探索',
        AITool: 'AI工具',
        CNSReplication: 'CNS复现',
        MechanismDiagram: '机制图',
        video: '视频演示',
        new: '新人必看',
        subtitle: '耗资上千万搭建的全国最大的在线科研数据（生信）分析云平台，彻底颠覆和变革传统数据分析工具和分析模式，只为带给您前所未有的数据分析体验!   每月持续上新30个可视化图表工具，涵盖全领域分析方法，将数据分析的便捷度提高到前所有未的高度！耗资上千万搭建的全国最大的',
        SVIP: '尊贵的',
        Hello: '您好',
        user: '尊敬的用户',
        login: '登录/注册',
        message: '消息中心',
        layOut: '退出登录',
        li1:'①推荐使用搜狗、谷歌浏览器；新注册有一个月免费试用期，请尽快体验，后续可通过分享转发继续免费使用！(为了避免出现不可控因素，禁止使用火狐，360浏览器)',
        li2:'②优先通过每个图表模块右侧表格文本框的复制粘贴（按键盘上的CtrlC+V）功能上传，尽量不要选择文件上传方式。上传数据的第一行不能缺少列名，A尤其是A-1格子必须要有列名！',
        li3:'③按键盘上“Ctrl+0” 恢复100%界面',
        li4:'④不要同时打开多个绘图工具，否则容易导致pdf下载后无法打开'
    },
    BottomContainAll:{
        folding:'一键折叠',
        expansion:'一键展开',
        top:'返回顶部'
    },
    PersonalCenter: {
        MyNews:"个人信息",
        VIPData: '会员到期日',
        extendTime:'延长期限',
        function: '功能分类',
        share: '新注册或者转发分享获得',
        pay: '付费会员',
        user: 'SVIP用户',
        allUse: '所有会员无限制任意使用',
        num: '500个数据分析模块无限制使用，每月上新30个新工具，图表用于发表文章可配合提供代码',
        td1: '文献检索工具无限制使用',
        td2: '实验机制/摘要图模块无限制任意使用',
        td3: '客服快速响应答疑',
        td4: '优先解决，服务时间：8:00-24:00',
        td5: '包含春节在内的全部节假日',
        td6: 'OpenAI官方GPT3.5+GPT-4o(可单独充值)，无需翻墙点开即用，按次收费无需高额月费',
        td7: '新注册赠送次数用完需要单独充值',
        td8: '每天',
        td9: '赠送',
        td10: '5个金币（等于',
        td11: '次GPT3.5提问次数/',
        td12: '次GPT4.0提问次数）',
        td13: '保存分析记录，随时查看历史分析数据和结果，进行二次分析或配色、参数调整',
        td14: '最多保存10条分析记录',
        td15: '最多保存200条分析记录',
        td16: 'SVIP专享权益（199/年）',
        td17: 'SVIP期间由来自复旦和交大的硕博客服提供无限次数据分析方法咨询指导服务，教您如何让自己辛苦得到的数据价值最大化',
        td18: '1次指定分析工具一周内加急上线服务',
        td19: '加入文献下载服务群，每天帮下载一篇自己没有权限下载的SCI文献',
        td120: 'SVIP期间每发表一篇引用CNSknowall平台的文章可获得100元现金红包（相当于99元年费SVIP，终身SVIP可终身领取每篇100元奖金，篇数无限制），添加客服直接微信转账',
        td21: '（查看引用格式）',
        td22: '私人专属一对一客服SVIP期间无限次指导如何选用适合自己数据的工具。科研绘图博大精深，CNSknowall团队在绘图方面积累了极其丰富的经验，获得我们专业的方法学指导可以让您少走很多弯路，节省大量时间，事半功倍！',
        td222:'R语言一比一复现精品代码下载权限',
        td23: '全平台所有图表的参数调整可以根据您的需求增加更个性化参数调整，当天完成优化，每晚19时平台更新后即可体验',
        td24: '加入SVIP专属微信服务群！我们专门为SVIP会员聘请了来自复旦、交大、中科院、北大等国内顶尖学府在生命科学领域的硕博，以及涵盖各种组学研究方向的顶级生信工程师，全程为大家服务，节假日无休，群内SVIP会员有问必答，获得最专业的数据分析方法或分析方向指导、各类实验流程指导、论文发表全流程指导、学业和职业规划指导！不定期分享一次极具参考价值的科研学习资料！',
        td25: '（查看SVIP服务群服务案例）',
        td26: '无需付费，转发分享朋友圈可获得1个月VIP会员，开发不易，感谢您的支持！',
        td27: '考虑到CNSKnowall平台的大部分用户都是无收入来源的在校学生，经济较为拮据，我们特此推出转发分享送会员活动，此活动永久有效（即永久免费）！',
        td28: '步骤一',
        td29: '关注公众号“CNSknowall”',
        td30: '可以在首页右侧直接扫码关注或微信搜索关注',
        td31: '步骤二',
        td32: '选择作者精选第一条推文《如果你分析科研数据感到很头疼，那是因为你不知道有这么宝藏的数据绘图平台!》转发朋友圈分享(不可设置分组可见)',
        td33: '步骤三',
        td34: '转发朋友圈后6h在右侧“+”上传朋友圈截图，可延长1月会员期限，同时根据点赞量额外赠送N天会员，N=点赞人数，比如100个人点赞赠送100天会员（如设置分组可见，屏蔽人数不能超过3，需要截图给客服验证）。',
        td35: '上传',
        td36: '已成功上传，等待审核',
        td37: '查询上传记录',
        td38: '每月上传一次',
        td39: '可一直免费使用哦！',
        td40: '体验前所未有的极速数据分析之旅，秒变分析大神！',
        td41: '（CNSknowall会员性价比之王）',
        td42: '扫码支付更便捷',
        td43: '终身SVIP合同下载',
        td44: 'SVIP期间每发表一篇引用CNSknowall平台的文章可奖励100元红包(篇数不限)',
        td45: '支持先开票后公对公转账，联系客服咨询',
        td55: '开具发票',
        td56: '(联系客服开取)',
        td57: '①数据分析工具使用权限购买',
        td58: '②数据分析指导服务',
        td59: '③科研技术服务',
        td60: '④数据分析服务',
        td61: '终身SVIP三人拼团价：999元每人，联系客服咨询',
        td62: '①文章方法学部分引用格式',
        td63: '②文章致谢内容引用格式',
        td64: '复制',
        td67: '格式仅供参考，也可采用其他表述但网址须一致!!',
        td68: ''
    }
}
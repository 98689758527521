<template>
  <div class="box">
    <el-breadcrumb separator=">">
      <el-breadcrumb-item
        @click.native="jump(breadcrumbs.c2)"
        :to="{
          path:
            breadcrumbs.c1name == '高级分析'
              ? '/Home/HighAll'
              : breadcrumbs.c2name == '单细胞分析'
              ? '/Cell'
              : breadcrumbs.c2name == '微生物生信'
              ? '/Home/microorganisms'
              : breadcrumbs.c2name == '肿瘤生信'
              ? '/Home/Tumorbioinformatics'
              : breadcrumbs.c2name == '转录组分析'
              ? '/Home/transcriptomeAnalysis'
              : breadcrumbs.c2name == '蛋白组分析'
              ? '/Home/ProteomeAnalysis':'',
        }"
        >{{ breadcrumbs.c2name }}</el-breadcrumb-item
      >
      <el-breadcrumb-item>{{ breadcrumbs.name }}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
import bus from "@/utils/bus";
export default {
  data() {
    return {
      pid: null,
      breadcrumbs: {},
    };
  },
  async mounted() {
    // console.log(sessionStorage.getItem("newPath"));
    bus.$on("info", (data) => {
      this.breadcrumbs = data;
      // console.log(this.breadcrumbs);
      if (data.length == 1) {
        this.breadcrumbs = data[0];
        // console.log(data,99999);
      } else {
        this.breadcrumbs.forEach((item) => {
          // 目前只添加两个判断，后续可加
          if (
            (sessionStorage.getItem("newPath") == "HighAll" &&
              item.c2name == "富集分析") 
          ) {
            //  if ( item.c2name = '富集分析') {
            this.breadcrumbs = item;
            //  }
          } else if (
            sessionStorage.getItem("newPath") == "transcriptomeAnalysis" &&
            item.c2name == "转录组分析"
          ) {
            this.breadcrumbs = item;
          } else {
            this.breadcrumbs = data[0];
          }
        });
      }
    });
  },
  methods: {
    jump(index) {
      if (this.breadcrumbs.c2name == "高级分析") {
        localStorage.setItem("High_ActiveName", index);
      }
    },
    // async getChartData() {
    //   let formData = new FormData();
    //   formData.append("pid", this.pid);
    //   const data = await this.$api.getDetailData(formData);
    //   this.breadcrumbs = data[0]
    // },
  },
};
</script>

<style lang="scss" scoped>
.box {
  margin-bottom: 7px; /* 6.13号修改 原数据为20 */
}
</style>
<template>
    <div class="tit_color" 
        style="margin: 20px 0; display: flex; justify-content: space-between; align-items: center; ">
        <label class="colorPicker item">{{label}}</label>
        <div class="iptBox" style="display: flex; align-items: center">
            <el-input type="number" placeholder="请输入" :min='min' :max="max" v-model="axisVal[0]" @change="axisValFun" />
            <span style="margin: 0 10px">—</span>
            <el-input type="number" placeholder="请输入" :min='min' :max="max" v-model="axisVal[1]" @change="axisValFun" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Array,
        },
        label: {
            type: String,
            default: '范围',
        },
        min: {
            type: Number,
            default: null,
        },
        max: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            axisVal: this.value,
        }
    },
    watch: {
        value: {
            handler(newVal) {
                this.axisVal = newVal;
            },
            deep: true,
        },
    },
    methods: {
        axisValFun() {
            if(this.axisVal[0]==''){
                this.axisVal[0]=null
            }
            if(this.axisVal[1]==''){
                this.axisVal[1]=null
            }
            this.$emit('input', this.axisVal); // 通过 input 事件更新父组件的数据
            this.$emit('drawChart'); // 触发自定义事件
        },
    }
}
</script>

<style lang="scss" scoped>
    ::v-deep .iptBox {

        /* element样式重置 start */
        /* 去掉input尾部上下小箭头 start */
        // input::-webkit-outer-spin-button,
        // input::-webkit-inner-spin-button {
        // -webkit-appearance: none !important;
        // -moz-appearance: none !important;
        // -o-appearance: none !important;
        // -ms-appearance: none !important;
        // appearance: none !important;
        // margin: 0;
        // }

        input[type="number"] {
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        -o-appearance: textfield;
        -ms-appearance: textfield;
        appearance: textfield;
        width: 93px;
        height: 34px;
        }
    }
</style>
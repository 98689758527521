
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import locale from 'element-ui/lib/locale';
import zh from './zh';
import en from './en';
 
Vue.use(VueI18n);
 
// 组合语言包
const messages = {
 zh: {
 ...zh,
 ...zhLocale
 },
 en: {
 ...en,
 ...enLocale
 }
};
 
// 获取存储语言或浏览器语言
const preferredLang = localStorage.getItem('preferred_lang');
let lang = preferredLang || (navigator.language || navigator.browserLanguage).toLowerCase();
 
// 创建 VueI18n 实例，并为 messages 和 locale 属性赋值
const i18n = new VueI18n({
 messages,
 locale: lang.substr(0, 2)
});
 
// element国际化动态切换
locale.i18n((key, value) => i18n.t(key, value));
 
// 导出
export default i18n;
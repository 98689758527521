<template>
  <el-backtop :target="Theclass">
    <div>
      <i style="vertical-align: middle">
        <svg
          t="1727425732663"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="3081"
          width="15"
          height="15"
        >
          <path
            d="M204.373 872.533a34.133 34.133 0 0 1-48.213-48.213l331.947-331.947a34.133 34.133 0 0 1 48.213 0L868.267 824.32a34.133 34.133 0 0 1-48.214 48.213L512 564.907z m0-341.333a34.133 34.133 0 0 1-48.213-48.213L488.107 151.04a34.133 34.133 0 0 1 48.213 0l331.947 331.947a34.133 34.133 0 0 1-48.214 48.213L512 223.573z"
            fill="#8a8a8a"
            p-id="3082"
          ></path>
        </svg>
      </i>
      <span style="margin-left: 4px">{{ $t("BottomContainAll.top") }}</span>
    </div>
  </el-backtop>
</template>

<script>
export default {
  props: {
    Theclass: {
      type: String,
      default: ".all .bottomContain",
    },
  },
};
</script>
<style lang="scss" scoped>
</style>